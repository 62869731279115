import Education from "models/Education";

export default [
  new Education(
    "Brno University of Technology",
    "MSc in Computer Graphics and Multimedia",
    new Date("2013-07-01"),
    new Date("2015-09-01"),
    "Brno",
    "CZ",
    "https://www.vutbr.cz"
  ),
  new Education(
    "Oulu University of Applied Sciences",
    "ERASMUS+ exchange program",
    new Date("2015-01-01"),
    new Date("2015-05-01"),
    "Oulu",
    "FI",
    "https://www.oamk.fi"
  ),
  new Education(
    "Brno University of Technology",
    "BSc in Computer Science",
    new Date("2010-07-01"),
    new Date("2013-07-01"),
    "Brno",
    "CZ",
    "https://www.vutbr.cz"
  )
];
