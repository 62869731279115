enum ELocations {
  ARGENTINA = "AR",
  CANADA = "CA",
  CHILE = "CL",
  COLOMBIA = "CO",
  CZECH_REPUBLIC = "CZ",
  FRANCE = "FR",
  GERMANY = "DE",
  JAPAN = "JP",
  MEXICO = "MX",
  NETHERLANDS = "NE",
  POLAND = "PL",
  RUSIA = "RU",
  SOUTH_AFRICA = "ZA",
  SPAIN = "ES",
  SWITZERLAND = "CH",
  UKRAINE = "UA",
  UNITED_KINGDOM = "UK",
  UNITED_STATES = "US",
  URUGUAY = "UY"
}

export default ELocations;
