import Contact from "models/Contact";

export default new Contact(
  "Vojtěch Šimetka",
  "Software Engineer | Volunteer",
  "I help my clients to deliver software solutions by understanding customer's needs, streamlining development processes and guiding teams. I am:",
  [
    {
      title: "Software Engineer",
      description:
        "This is what I love to do the most. I have been building software solutions for more than 10 years. My area of expertise spawns from Blockchain Technology, Fullstack development to Computer Vision and Artificial Intelligence."
    },
    {
      title: "Volunteer",
      description:
        "I strongly believe in the power individuals have to make a positive impact in the world and society. Let's change the world together join our Blockchain 4 Humanity initiative or become a part of #coalition4GOOD."
    },
    {
      title: "Blockchain Expert",
      description:
        "I have been very active in the blockchain space since 2017 developing a number of solutions on Ethereum blockchain. Do you want to learn or need help? Contact me."
    },
    {
      title: "Public Speaker",
      description:
        "I always wanted to be a teacher. Speaking gives me the opportunity to pass what I've learned to others. Let it be developers learning how to build blockchain based solutions, experts on social impact who want to learn about blockchain technology use-cases or high school students interested in what it is like to be an entrepreneur. Do you have an audience that would benefit from one of these topics?"
    },
    {
      title: "Digital Nomad",
      description:
        "I don't have a permanent home which allows me to explore the world, get to know new people and cultures and be where I need to be when I need to be there. Send me your favourite place."
    }
  ],
  "vojtechsimetka",
  "vojtechsimetka",
  "vojtech.simetka",
  "vojtechsimetka",
  "vojtechsimetka",
  "vojtechsimetka",
  [
    { name: "vojtech", domain: "simetka.cz" },
    { name: "vojtech", domain: "b4h.world" },
    { name: "vojtech", domain: "iovlabs.org" }
  ]
);
